/* CTA */
.cta {
	color:rgba(7, 26, 134, 0.95) !important;
	text-transform: uppercase;
	text-decoration: none;
	background: #ffffff;
	padding: 10px;
	border: 2px solid rgba(7, 26, 134, 0.95) !important;
  border-radius: 30px;
	display: inline-block;
	transition: all 0.4s ease 0s;

}
.cta:hover {
	color: #ffffff !important;
	background:rgba(7, 26, 134, 0.95);
	border-color: rgba(7, 26, 134, 0.95) !important;
	transition: all 0.4s ease 0s;
}
.cta span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}
.cta span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.2s;
}
.cta:hover span {
  padding-right: 25px;
}
.cta:hover span:after {
  opacity: 1;
  right: 0;
}
/* Social Media */
.link-unstyled, .link-unstyled:link {
  color: inherit;
  text-decoration: inherit;
}
.link-unstyled:hover {
  color: rgba(7, 26, 134);
}

.hero {
  height: 70vh;
  position: relative;
  width: 100%;

}
.hero img {
  height: 70vh;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}
.overlay{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(24, 28, 48, 0.7);
}
.thankyou {
  height: 400px;
  line-height: 400px;
  text-align: center;
}
.thankyou span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.App {
  text-align: center;
}
/* Spinner */
.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
/* List of jobads */
.listings {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.listing {
  background-color: #f4f4f4;
  margin: 10px 0;
  padding: 20px 0 10px;
  position: relative;
}

.listing .close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;

}
/* Cards */

.cardsImg {
  width: 100%;
}

.cards {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 2rem;
  background: #f1f1f1;
  margin-bottom: 2rem;
}

.cards h2 {
  margin-bottom: 2rem;
}

.cards img {
  height: 400px;
}


.card-img-overlay {
  background: rgba(7, 26, 134, 0.7)
}

/* Footer */
.footer {
  color: #ffff;
  min-height: 150px;
  background-color: rgba(7, 26, 134, 0.3);
  
}
.minHeight {
position: relative;
}
.kompetensplus {
  color:#fff;
}
.kranmarkt {
  height: 100px;
  max-width: 100px;
}
.gridCorp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
}
.gridCorp > div {
  padding: 1rem;
  display: grid;
  place-items: center;
}

.gridCorp > div img {
  width: 100%;
  grid-area: 1/1/2/2;
}
.swea{
padding: 50px;
}
.copyright {
  min-height: 3rem;
}

/* Margins */

.margin50 {
  margin-top: 150px;
}
.vertical-center {
  margin-top: 50%;
}

.centered {
  font-size: 38px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#services {
  background: url(../src/img/bakgrunder/patrik.png) no-repeat top center fixed;
  background-size: cover;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}
#services::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(70, 99, 91, 0.4);
}

.servicesIndex {
  z-index: 999;
  color: white;
}
.anchor {
  position: absolute;
  transform: translateY(-50vh);
}
/* Contact icons */
#box{
  width: 400px;
  height: 400px;
  position: relative;
  border-radius: 100%;
border: 1px solid teal;
}
.dot{
    position: absolute;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    background: rebeccapurple;
}
#box img{
  width: 40px;
  height: 40px;
  position: absolute;
border-radius: 100%;
  -webkit-transition: all 1s;
}
@media only screen and (max-width: 1000px) {
  .swea{
    padding: 70px;
  }
}
@media only screen and (max-width: 789px) {
  .cards {
    display: block;
  }
  .gridCorp > div img {
    grid-area: 1/1/2/2;
  }
  .swea{
    padding: 50px;
    }
}
@media only screen and (max-width: 580px) {

  .gridCorp > div img {
    width: 50%;
    grid-area: 1/1/2/2;
  }
  .gridCorp {
    grid-gap: 0rem;
  }
}
@media only screen and (max-width: 380px) {

  .gridCorp > div img {
    width: 80%;
    grid-area: 1/1/2/2;
  }
  .gridCorp {
    grid-gap: 0rem;
  }
}